/**
 * It's a self contained react-redux module
 * It's a 'feature'
 * It could be shown in Widget, Panel, or Modal.
 */
var NotificationFlag;
(function (NotificationFlag) {
  NotificationFlag["unread"] = "unread";
  NotificationFlag["read"] = "read";
  NotificationFlag["actionPending"] = "actionPending";
  NotificationFlag["actionDone"] = "actionDone";
  NotificationFlag["seen"] = "seen";
  NotificationFlag["unseen"] = "unseen";
  NotificationFlag["starred"] = "starred";
})(NotificationFlag || (NotificationFlag = {}));
/**
 * Declare where panel going to be positioned, and
 * What could happen to parent panels
 */
var CreateModes;
(function (CreateModes) {
  // adds new panel to start without interfering with others
  CreateModes[CreateModes["ADD"] = 0] = "ADD";
  // can slide and stack towards to the end
  CreateModes[CreateModes["STACK"] = 1] = "STACK";
  // positions it self to the end of the parent component
  CreateModes[CreateModes["SLIDE_MORE"] = 2] = "SLIDE_MORE";
  // creates an overlay over parent component, and positions panel to start
  CreateModes[CreateModes["OVERLAY_OVER_PARENT"] = 3] = "OVERLAY_OVER_PARENT";
})(CreateModes || (CreateModes = {}));
// Tab interface
var TabId;
(function (TabId) {
  TabId["APP_PANEL"] = "APP_PANEL";
  TabId["PERSONAL_PANEL"] = "PERSONAL_PANEL";
  TabId["ORGANIZATION_PANEL"] = "ORGANIZATION_PANEL";
  TabId["MARKET_PANEL"] = "MARKET_PANEL";
  TabId["SETTING_PREFERENCE_PANEL"] = "SETTING_PREFERENCE_PANEL";
  TabId["ACTIVITY_PANEL"] = "ACTIVITY_PANEL";
  TabId["PERFORMANCE_MONITOR"] = "PERFORMANCE_MONITOR";
  TabId["CMS_SHORTCUT"] = "CMS_SHORTCUT";
  TabId["FLOW_CENTER"] = "FLOW_CENTER";
  TabId["ADMIN_SHORTCUT"] = "ADMIN_CENTER";
  TabId["DATA_CENTER"] = "DATA_CENTER";
  TabId["RESOURCE_CENTER"] = "RESOURCE_CENTER";
  TabId["ENGINE_CENTER"] = "ENGINE_CENTER";
  TabId["LIBRARY_CENTER"] = "LIBRARY_CENTER";
  TabId["MARKETPLACE_CENTER"] = "MARKETPLACE_CENTER";
  TabId["PROCESSING_CENTER"] = "PROCESSING_CENTER";
  TabId["PROCESSING_CENTER_DAG_BUILDER"] = "PROCESSING_CENTER_DAG_BUILDER";
  TabId["INSTANCE_ADMIN_CENTER_BROWSE"] = "INSTANCE_ADMIN_CENTER_BROWSE";
})(TabId || (TabId = {}));
var EUserStatus;
(function (EUserStatus) {
  EUserStatus["active"] = "active";
  EUserStatus["suspended"] = "suspended";
  EUserStatus["deleted"] = "deleted";
  EUserStatus["deactivated"] = "deactivated";
})(EUserStatus || (EUserStatus = {}));
var AvailableWidgets;
(function (AvailableWidgets) {
  AvailableWidgets["SAMPLE_APP_BAR"] = "SAMPLE_APP_BAR";
  AvailableWidgets["APP_BAR"] = "APP_BAR";
  AvailableWidgets["HEADER_ACTIONS"] = "HEADER_ACTIONS";
  AvailableWidgets["IMPERSONATION_BANNER"] = "IMPERSONATION_BANNER";
  AvailableWidgets["FLOW_CENTER"] = "FLOW_CENTER";
  AvailableWidgets["FLOW_WIDGET"] = "FLOW_WIDGET";
  AvailableWidgets["ENGINE_CENTER_BROWSE"] = "ENGINE_CENTER_BROWSE";
  AvailableWidgets["MACHINE_BOX_BROWSE"] = "MACHINE_BOX_BROWSE";
  AvailableWidgets["DEVELOPER_OVERVIEW"] = "DEVELOPER_OVERVIEW";
  AvailableWidgets["APP_CENTER_BROWSE"] = "APP_CENTER_BROWSE";
  AvailableWidgets["RESET_PASSWORD"] = "RESET_PASSWORD";
  AvailableWidgets["VIEWER_CENTER_BROWSE"] = "VIEWER_CENTER_BROWSE";
  AvailableWidgets["AUDIT_LOGS_WIDGET"] = "AUDIT_LOGS_WIDGET";
})(AvailableWidgets || (AvailableWidgets = {}));
var AvailablePanels;
(function (AvailablePanels) {
  AvailablePanels["SIMPLE_PANEL"] = "SIMPLE_PANEL";
  AvailablePanels["APP_BAR_PANEL_TEMPLATE"] = "APP_BAR_PANEL_TEMPLATE";
  AvailablePanels["APP_BAR_TAB_PANEL_TEMPLATE"] = "APP_BAR_TAB_PANEL_TEMPLATE";
  AvailablePanels["TAB_TEMPLATE"] = "TAB_TEMPLATE";
})(AvailablePanels || (AvailablePanels = {}));
var AvailableComponents;
(function (AvailableComponents) {
  AvailableComponents["ADD_APPLICATIONS"] = "ADD_APPLICATIONS";
  AvailableComponents["ADD_APPLICATION_USERS_PANEL"] = "ADD_APPLICATION_USERS_PANEL";
  AvailableComponents["ADMIN_CENTER"] = "ADMIN_CENTER";
  AvailableComponents["ADMIN_CENTER_ADD_MEMBER_PANEL"] = "ADMIN_CENTER_ADD_MEMBER_PANEL";
  AvailableComponents["ADMIN_CENTER_APPROVAL_REQUESTS_PANEL"] = "ADMIN_CENTER_APPROVAL_REQUESTS_PANEL";
  AvailableComponents["ADMIN_CENTER_CUSTOM_FIELDS_FORM"] = "ADMIN_CENTER_CUSTOM_FIELDS_FORM";
  AvailableComponents["ADMIN_CENTER_CUSTOM_LOGIN_PANEL"] = "ADMIN_CENTER_CUSTOM_LOGIN_PANEL";
  AvailableComponents["ADMIN_CENTER_GROUP_DETAILS_PANEL"] = "ADMIN_CENTER_GROUP_DETAILS_PANEL";
  AvailableComponents["ADMIN_CENTER_NEW_GROUP_PANEL"] = "ADMIN_CENTER_NEW_GROUP_PANEL";
  AvailableComponents["ADMIN_CENTER_OPEN_ID"] = "ADMIN_CENTER_OPEN_ID";
  AvailableComponents["APPLICATION_DETAILS_PANEL"] = "APPLICATION_DETAILS_PANEL";
  AvailableComponents["APP_BAR_PANEL"] = "APP_BAR_PANEL";
  AvailableComponents["APP_CENTER_ADD_FLOW_PANEL"] = "APP_CENTER_ADD_FLOW_PANEL";
  AvailableComponents["APP_CENTER_ADD_SCHEMA_PANEL"] = "APP_CENTER_ADD_SCHEMA_PANEL";
  AvailableComponents["APP_CENTER_APP_ROLES"] = "APP_CENTER_APP_ROLES";
  AvailableComponents["APP_CENTER_AUTOMATE_PALETTE_INTEGRATION_PANEL"] = "APP_CENTER_AUTOMATE_PALETTE_INTEGRATION_PANEL";
  AvailableComponents["APP_CENTER_BROWSE"] = "APP_CENTER_BROWSE";
  AvailableComponents["APP_CENTER_CONFIG_FIELDS_FORM"] = "APP_CENTER_CONFIG_FIELDS_FORM";
  AvailableComponents["APP_CENTER_CONTEXT_MENU_EXTENSIONS"] = "APP_CENTER_CONTEXT_MENU_EXTENSIONS";
  AvailableComponents["APP_CENTER_DETAILS"] = "APP_CENTER_DETAILS";
  AvailableComponents["APP_CENTER_EVENT_PANEL"] = "APP_CENTER_EVENT_PANEL";
  AvailableComponents["APP_CENTER_EVENTS"] = "APP_CENTER_EVENTS";
  AvailableComponents["APP_CENTER_FILTER"] = "APP_CENTER_FILTER";
  AvailableComponents["APP_CENTER_NEW"] = "APP_CENTER_NEW";
  AvailableComponents["AUDIT_LOGS_PANEL"] = "AUDIT_LOGS_PANEL";
  AvailableComponents["CHANGELOG_PANEL"] = "CHANGELOG_PANEL";
  AvailableComponents["CREATE_ORGANIZATION_PANEL"] = "CREATE_ORGANIZATION_PANEL";
  AvailableComponents["CREATE_OR_UPDATE_TOKEN_PANEL"] = "CREATE_OR_UPDATE_TOKEN_PANEL";
  AvailableComponents["CREATE_SCHEMA_PANEL"] = "CREATE_SCHEMA_PANEL";
  AvailableComponents["DATA_CENTER"] = "DATA_CENTER";
  AvailableComponents["DATA_CENTER_DATA_DETAILS_PANEL"] = "DATA_CENTER_DATA_DETAILS_PANEL";
  AvailableComponents["DATA_CENTER_DATA_REGISTRY_INFO"] = "DATA_CENTER_DATA_REGISTRY_INFO";
  AvailableComponents["DATA_CENTER_EDIT_METADATA_PANEL"] = "DATA_CENTER_EDIT_METADATA_PANEL";
  AvailableComponents["DATA_CENTER_ENGINE_SELECTION"] = "DATA_CENTER_ENGINE_SELECTION";
  AvailableComponents["DATA_CENTER_EXPORT_TDO_PANEL"] = "DATA_CENTER_EXPORT_TDO_PANEL";
  AvailableComponents["DATA_CENTER_FILE_INFO_PANEL"] = "DATA_CENTER_FILE_INFO_PANEL";
  AvailableComponents["DATA_CENTER_FOLDERS_AND_FILES"] = "DATA_CENTER_FOLDERS_AND_FILES";
  AvailableComponents["DATA_CENTER_IMPORTER"] = "DATA_CENTER_IMPORTER";
  AvailableComponents["DATA_CENTER_MDP_IFRAME_PANEL"] = "DATA_CENTER_MDP_IFRAME_PANEL";
  AvailableComponents["DATA_CENTER_PROCESS_AI"] = "DATA_CENTER_PROCESS_AI";
  AvailableComponents["DATA_CENTER_SCHEDULE_INFO_PANEL"] = "DATA_CENTER_SCHEDULE_INFO_PANEL";
  AvailableComponents["DATA_CENTER_SELECT_IMPORT_SOURCE"] = "DATA_CENTER_SELECT_IMPORT_SOURCE";
  AvailableComponents["DATA_CENTER_SOURCE_INFO_PANEL"] = "DATA_CENTER_SOURCE_INFO_PANEL";
  AvailableComponents["DATA_CENTER_SDO_VIEWER"] = "DATA_CENTER_SDO_VIEWER";
  AvailableComponents["DATA_LABELER"] = "DATA_LABELER";
  AvailableComponents["EDIT_DATA_REGISTRY_PANEL"] = "EDIT_DATA_REGISTRY_PANEL";
  AvailableComponents["EDIT_FILE_METADATA"] = "EDIT_FILE_METADATA";
  AvailableComponents["EDIT_PROFILE_PICTURE"] = "EDIT_PROFILE_PICTURE";
  AvailableComponents["EDIT_SCHEMA_PANEL"] = "EDIT_SCHEMA_PANEL";
  AvailableComponents["ENGINE_CENTER_CUSTOM_FIELDS_FORM"] = "ENGINE_CENTER_CUSTOM_FIELDS_FORM";
  AvailableComponents["ENGINE_CENTER_DETAILS"] = "ENGINE_CENTER_DETAILS";
  AvailableComponents["ENGINE_CENTER_EDIT_RELEASE_NOTES"] = "ENGINE_CENTER_EDIT_RELEASE_NOTES";
  AvailableComponents["ENGINE_CENTER_FILTER"] = "ENGINE_CENTER_FILTER";
  AvailableComponents["ENGINE_CENTER_INPUT_TYPE_FLYOUT"] = "ENGINE_CENTER_INPUT_TYPE_FLYOUT";
  AvailableComponents["ENGINE_CENTER_NEW"] = "ENGINE_CENTER_NEW";
  AvailableComponents["ENGINE_CENTER_NEW_BUILD"] = "ENGINE_CENTER_NEW_BUILD";
  AvailableComponents["ENGINE_CENTER_SCHEMAS_FORM_PANEL"] = "ENGINE_CENTER_SCHEMAS_FORM_PANEL";
  AvailableComponents["ENGINE_CENTER_VERSION_HISTORY_PANEL"] = "ENGINE_CENTER_VERSION_HISTORY_PANEL";
  AvailableComponents["EVENT_CENTER_SUBSCRIPTIONS"] = "EVENT_CENTER_SUBSCRIPTIONS";
  AvailableComponents["FLOW_DETAILS_PANEL"] = "FLOW_DETAILS_PANEL";
  AvailableComponents["FLOW_TEMPLATE_DETAILS_PANEL"] = "FLOW_TEMPLATE_DETAILS_PANEL";
  AvailableComponents["GROUP_INFO_PANEL"] = "GROUP_INFO_PANEL";
  AvailableComponents["HELP_PANEL"] = "HELP_PANEL";
  AvailableComponents["INSTANCE_ADMIN_CENTER_BROWSE"] = "INSTANCE_ADMIN_CENTER_BROWSE";
  AvailableComponents["INVITE_REQUESTS_PANEL"] = "INVITE_REQUESTS_PANEL";
  AvailableComponents["INVITE_USERS_PANEL"] = "INVITE_USERS_PANEL";
  AvailableComponents["JOBS_DETAILS_PANEL"] = "JOBS_DETAILS_PANEL";
  AvailableComponents["JOBS_TABLE_SETTINGS_PANEL"] = "JOBS_TABLE_SETTINGS_PANEL";
  AvailableComponents["MANAGE_GRANTS"] = "MANAGE_GRANTS";
  AvailableComponents["MARKETPLACE"] = "MARKETPLACE";
  AvailableComponents["MARKETPLACE_CART"] = "MARKETPLACE_CART";
  AvailableComponents["MARKETPLACE_ITEM_DETAILS"] = "MARKETPLACE_ITEM_DETAILS";
  AvailableComponents["NEW_PERMISSION_SET_PANEL"] = "NEW_PERMISSION_SET_PANEL";
  AvailableComponents["NODE_DETAILS_PANEL"] = "NODE_DETAILS_PANEL";
  AvailableComponents["NOTIFICATION_APPLICATION"] = "NOTIFICATION_APPLICATION";
  AvailableComponents["NOTIFICATION_DETAIL"] = "NOTIFICATION_DETAIL";
  AvailableComponents["NOTIFICATION_PANEL"] = "NOTIFICATION_PANEL";
  AvailableComponents["NOTIFICATION_SETTING"] = "NOTIFICATION_SETTING";
  AvailableComponents["NOTIFICATION_UTILITY"] = "NOTIFICATION_UTILITY";
  AvailableComponents["ORGANIZATION_PANEL"] = "ORGANIZATION_PANEL";
  AvailableComponents["PACKAGE_DETAILS_PANEL"] = "PACKAGE_DETAILS_PANEL";
  AvailableComponents["PACKAGE_MANAGER_PANEL"] = "PACKAGE_MANAGER_PANEL";
  AvailableComponents["PACKAGE_SETUP_PANEL"] = "PACKAGE_SETUP_PANEL";
  AvailableComponents["PACKAGE_SETTINGS_PANEL"] = "PACKAGE_SETTINGS_PANEL";
  AvailableComponents["PALETTE_DETAILS_PANEL"] = "PALETTE_DETAILS_PANEL";
  AvailableComponents["PERMISSIONS_PANEL"] = "PERMISSIONS_PANEL";
  AvailableComponents["PERMISSION_SET_DETAILS_PANEL"] = "PERMISSION_SET_DETAILS_PANEL";
  AvailableComponents["PROCESSING_CENTER"] = "PROCESSING_CENTER";
  AvailableComponents["PROCESSING_CENTER_ADVANCED_SETTINGS"] = "PROCESSING_CENTER_ADVANCED_SETTINGS";
  AvailableComponents["PROCESSING_CENTER_AFFILIATE_PANEL"] = "PROCESSING_CENTER_AFFILIATE_PANEL";
  AvailableComponents["PROCESSING_CENTER_BULK_PROVISIONING_PANEL"] = "PROCESSING_CENTER_BULK_PROVISIONING_PANEL";
  AvailableComponents["PROCESSING_CENTER_DAG_BUILDER"] = "PROCESSING_CENTER_DAG_BUILDER";
  AvailableComponents["PROCESSING_CENTER_DAG_DETAILS_PANEL"] = "PROCESSING_CENTER_DAG_DETAILS_PANEL";
  AvailableComponents["PROCESSING_CENTER_ERROR_DETAIL"] = "PROCESSING_CENTER_ERROR_DETAIL";
  AvailableComponents["PROCESSING_CENTER_INGESTION"] = "PROCESSING_CENTER_INGESTION";
  AvailableComponents["PROCESSING_CENTER_JOBS"] = "PROCESSING_CENTER_JOBS";
  AvailableComponents["PROCESSING_CENTER_SOURCES"] = "PROCESSING_CENTER_SOURCES";
  AvailableComponents["RESOURCE_CENTER"] = "RESOURCE_CENTER";
  AvailableComponents["REVIEW_ORG_INVITE_PANEL"] = "REVIEW_ORG_INVITE_PANEL";
  AvailableComponents["SCHEMA_DETAILS_PANEL"] = "SCHEMA_DETAILS_PANEL";
  AvailableComponents["SETTING_PREFERENCE"] = "SETTING_PREFERENCE";
  AvailableComponents["SUPPORTED_FILE_TYPES"] = "SUPPORTED_FILE_TYPES";
  AvailableComponents["TASK_DETAILS_PANEL"] = "TASK_DETAILS_PANEL";
  AvailableComponents["TASKS_TABLE_SETTINGS_PANEL"] = "TASKS_TABLE_SETTINGS_PANEL";
  AvailableComponents["TEST_COMPONENT"] = "TEST_COMPONENT";
  AvailableComponents["TEST_COMPONENT2"] = "TEST_COMPONENT2";
  AvailableComponents["TEST_COMPONENT3"] = "TEST_COMPONENT3";
  AvailableComponents["USER_APPLICATION_SETTINGS_PANEL"] = "USER_APPLICATION_SETTINGS_PANEL";
  AvailableComponents["USER_DETAILS_PANEL"] = "USER_DETAILS_PANEL";
  AvailableComponents["VERSION_PANEL"] = "VERSION_PANEL";
  AvailableComponents["VIEWER_CENTER_NEW"] = "VIEWER_CENTER_NEW";
  AvailableComponents["UPSERT_INTEGRATION_PANEL"] = "UPSERT_INTEGRATION_PANEL";
})(AvailableComponents || (AvailableComponents = {}));
var EntityType;
(function (EntityType) {
  EntityType["AiPackages"] = "aiPackages";
  EntityType["EngineCategories"] = "engineCategories";
  EntityType["Engines"] = "engines";
  EntityType["SourceTypes"] = "sourceTypes";
  EntityType["Folders"] = "folders";
  EntityType["Sources"] = "sources";
  EntityType["Schedules"] = "schedules";
  EntityType["Tdos"] = "tdos";
  EntityType["Jobs"] = "jobs";
})(EntityType || (EntityType = {}));
var SdkEvents;
(function (SdkEvents) {
  SdkEvents["fileUpload"] = "fileUpload";
  SdkEvents["fileUploadProgress"] = "fileUploadProgress";
  SdkEvents["tdoCreated"] = "tdoCreated";
  SdkEvents["jobCreated"] = "jobCreated";
  SdkEvents["languageChange"] = "languageChange";
  SdkEvents["permissionChanged"] = "permissionChanged";
  SdkEvents["openSupport"] = "openSupport";
  SdkEvents["tokenUpdated"] = "tokenUpdated";
})(SdkEvents || (SdkEvents = {}));

export { AvailableComponents, AvailablePanels, AvailableWidgets, CreateModes, EUserStatus, EntityType, NotificationFlag, SdkEvents, TabId };
